// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/utilities";

// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/cards";
@import "elements/tabs";

// Applications

@import "applications/applications-base";

// Tables
@import "components/tables/tables";

// Icons
@import "components/icons/p7icons";

// FORMS

@import "components/forms/datepicker/datepicker";
@import "components/forms/multiselect/multiselect";
@import "components/forms/toggle-switch/switch";

// CUSTOM
@import "custom/custom.scss";
